import React from 'react';
import {
  useParams
} from 'react-router-dom';
import { getShortUrl } from '../service';

export const ShortLink = (  ) => {
  const [shortUrl, setShortUrl] = React.useState(undefined);
  const { shortCode } = useParams()

  React.useEffect(() => {
    async function fetchData() {
      try {
        const result = await getShortUrl(shortCode);
        console.log(result);
        if (result) {
          setShortUrl(result);
        } else {
          setShortUrl({targetUrl: 'https://diatra-verlag.de'});  
        }
      } catch (ex) {
        setShortUrl({targetUrl: 'https://diatra-verlag.de'});
      }
    }
    fetchData();
  }, [shortCode]);

  if (shortUrl) {
      window.location.replace(shortUrl.targetUrl);
  }

  return <div></div>;
  
};