import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import {
    ShortLink
} from './components'
import './App.css';
import { DotLoader } from "react-spinners";

function App() {
    let [loading, setLoading] = React.useState(true);
    let [color, setColor] = React.useState("#EE7900");

    return (
        <Router>
            <div class="px-4 py-5 my-5 text-center">
                <img class="d-block mx-auto mb-5" src="logo_full.png" className="App-logo" alt=""/>
                <h1 class="display-5 fw-bold mt-4">Link Shortener</h1>
                <div class="col-lg-6 mx-auto">
                <p class="lead mb-4">Willkommen beim DIATRA Link Shortener.<br/> Sie werden gleich weitergeleitet.</p>
                <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                    <DotLoader color={color} size={60} loading={loading} />
                </div>
                </div>
            </div>

                <Switch>
                    <Route path='/' exact component={() => {
                        window.location.replace('https://diatra-verlag.de');
                        return <div></div>
                    }}>
                    </Route>
                    <Route path='/:shortCode' exact>
                        <ShortLink />
                    </Route>
                </Switch>
                <div class="container">
                    <footer class="py-3 my-4">
                        <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                        <li class="nav-item"><a href="https://diatra-verlag.de/datenschutzerklaerung/" class="nav-link px-2 text-muted">Datenschutz</a></li>
                        <li class="nav-item"><a href="https://diatra-verlag.de/kontakt/" class="nav-link px-2 text-muted">Impressum</a></li>
                        </ul>
                        <p class="text-center text-muted">&copy; {new Date().getFullYear()} Diatra-Verlag gGmbH</p>
                    </footer>
                </div>
        </Router>
    );
}

export default App;
